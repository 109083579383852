































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Fraction } from '@/helper/helperIndex';

interface JobDetails {
    jobTitle: string;
    companyName?: string;
    description: string;
    logo?: string;
}

@Component
export default class Card extends Vue {
    @Prop() isDirectionLeft!: any;
    @Prop() effectEnd!: any;
    @Prop() difference!: any;
    @Prop() jobDetails!: JobDetails;

    scrollVariable: number = 0;
    get effectStart(): number {
        return this.effectEnd - (this.difference ? this.difference : 500);
    }
    get cardSlideEffect(): string {
        return (
            (this.isDirectionLeft
                ? (Fraction(this.effectStart, this.effectEnd, this.scrollVariable) - 1) * 20
                : (1 - Fraction(this.effectStart, this.effectEnd, this.scrollVariable)) * 20) + '%'
        );
    }

    get imgUrl() {
        var images = require.context('@/assets/', false, /\.png$/);
        return this.jobDetails.logo ? images('./' + this.jobDetails.logo + '.png') : '';
    }

    onScroll(event: any) {
        this.scrollVariable = event.target.scrollTop;
    }
}
